export const COUNTRY_USA = 'USA';

export const orderedCountries = [
  'USA',
  'CAN',
  'GBR',
  'AUS',
  'DEU',
  'FRA',
  'CHE',
  'ESP',
  'ITA',
  'BEL',
  'JPN',
];
