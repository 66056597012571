export const calculateYearsOfCoachingExperience = (coachingStartYear: number) => {
  const currentYear = new Date().getFullYear();

  if (coachingStartYear >= 1000) {
    // Assuming input is a year
    if (coachingStartYear > currentYear) {
      throw new Error("Input year cannot be in the future.");
    }
    return currentYear - coachingStartYear;
  } else if (coachingStartYear > 0) {
    // Assuming input is a number of years
    return currentYear - coachingStartYear;
  } else if (coachingStartYear === 0) {
    // Assuming input is a number of years
    return coachingStartYear;
  } else {
    throw new Error("coachingStartYear must be a positive number.");
  }
};
