import * as React from 'react';

export default function Cog({ className, viewBox }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      viewBox={viewBox || '0 0 16 16'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.19147 2.53636C8.88826 1.28736 7.11194 1.28736 6.80872 2.53636C6.61285 3.3432 5.68846 3.7261 4.97943 3.29408C3.88184 2.62531 2.6258 3.88135 3.29457 4.97894C3.72659 5.68797 3.34369 6.61236 2.53685 6.80823C1.28785 7.11145 1.28785 8.88777 2.53685 9.19099C3.34369 9.38686 3.72659 10.3112 3.29457 11.0203C2.6258 12.1179 3.88184 13.3739 4.97943 12.7051C5.68846 12.2731 6.61285 12.656 6.80872 13.4629C7.11194 14.7119 8.88826 14.7119 9.19147 13.4629C9.38735 12.656 10.3117 12.2731 11.0208 12.7051C12.1184 13.3739 13.3744 12.1179 12.7056 11.0203C12.2736 10.3112 12.6565 9.38686 13.4633 9.19099C14.7123 8.88777 14.7123 7.11145 13.4633 6.80823C12.6565 6.61236 12.2736 5.68797 12.7056 4.97894C13.3744 3.88135 12.1184 2.62531 11.0208 3.29408C10.3117 3.7261 9.38735 3.3432 9.19147 2.53636ZM8.0001 10.3996C9.32558 10.3996 10.4001 9.32509 10.4001 7.99961C10.4001 6.67413 9.32558 5.59961 8.0001 5.59961C6.67461 5.59961 5.6001 6.67413 5.6001 7.99961C5.6001 9.32509 6.67461 10.3996 8.0001 10.3996Z"
        fill="currentColor"
      />
    </svg>
  );
}
