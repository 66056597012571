import * as React from 'react';

export default function AddPhoto({
  className,
  viewBox = '0 0 24 24',
}: React.SVGProps<SVGSVGElement>) {
  return (
    <svg className={className} viewBox={viewBox} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2374_131192)">
        <path
          d="M3.5625 8.25C3.5625 8.76562 3.98438 9.1875 4.5 9.1875C5.01562 9.1875 5.4375 8.76562 5.4375 8.25V6.375H7.3125C7.82812 6.375 8.25 5.95312 8.25 5.4375C8.25 4.92188 7.82812 4.5 7.3125 4.5H5.4375V2.625C5.4375 2.10938 5.01562 1.6875 4.5 1.6875C3.98438 1.6875 3.5625 2.10938 3.5625 2.625V4.5H1.6875C1.17188 4.5 0.75 4.92188 0.75 5.4375C0.75 5.95312 1.17188 6.375 1.6875 6.375H3.5625V8.25Z"
          fill="currentColor"
        />
        <path
          d="M12.9375 16.6875C14.4908 16.6875 15.75 15.4283 15.75 13.875C15.75 12.3217 14.4908 11.0625 12.9375 11.0625C11.3842 11.0625 10.125 12.3217 10.125 13.875C10.125 15.4283 11.3842 16.6875 12.9375 16.6875Z"
          fill="currentColor"
        />
        <path
          d="M20.4375 6.375H17.4656L16.3031 5.10938C15.9563 4.725 15.45 4.5 14.925 4.5H8.925C9.08438 4.78125 9.1875 5.09062 9.1875 5.4375C9.1875 6.46875 8.34375 7.3125 7.3125 7.3125H6.375V8.25C6.375 9.28125 5.53125 10.125 4.5 10.125C4.15312 10.125 3.84375 10.0219 3.5625 9.8625V19.5C3.5625 20.5312 4.40625 21.375 5.4375 21.375H20.4375C21.4688 21.375 22.3125 20.5312 22.3125 19.5V8.25C22.3125 7.21875 21.4688 6.375 20.4375 6.375ZM12.9375 18.5625C10.35 18.5625 8.25 16.4625 8.25 13.875C8.25 11.2875 10.35 9.1875 12.9375 9.1875C15.525 9.1875 17.625 11.2875 17.625 13.875C17.625 16.4625 15.525 18.5625 12.9375 18.5625Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2374_131192">
          <rect width="22.5" height="22.5" fill="white" transform="translate(0.75 0.75)" />
        </clipPath>
      </defs>
    </svg>
  );
}
